@import '~leaflet/dist/leaflet.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '~filepond/dist/filepond.min.css';
@import '~filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
@import '~filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

.slick-prev:before,
.slick-next:before {
  color: black !important;
}


.mapbox-autocomplete-form {
  width: 100%;
  max-width: 100%;
  z-index: 1010;

  mapbox-search-box {
    > div {
      width: 100%;
      max-width: 100%;
      box-shadow: none;
      border: solid 1px #bdbdbd;
      font: 15px/20px 'Lato', 'Helvetica', 'Arial', sans-serif;
    }

    input {
      width: 100%;
      max-width: 100%;
      outline: none;
      margin: 8px 0;
      font-size: '24 !important';
    }
  }

  [class$="--ResultsAttribution"] {
    display: none;
  }
}

.mapbox-autocomplete-searchBar {
  min-width: 100%;
  max-width: 100%;
  z-index: 1010;

  mapbox-search-box {
    > div {
      min-width: 100%;
      max-width: 100%;
      box-shadow: none;
      border: solid 1px #bdbdbd;
      font: 15px/20px 'Lato', 'Helvetica', 'Arial', sans-serif;
      background-color: #e6e6e6;
    }

    input {
      // width: 100%;
      max-width: 100%;
      outline: none;
      margin: 8px 0;
      font-size: '24 !important';
      height: 38px;
    }
  }

  [class$="--ResultsAttribution"] {
    display: none;
  }
}

.mapbox-autocomplete-searchBar-mormal {
  min-width: 100%;
  max-width: 100%;
  z-index: 1010;

  mapbox-search-box {
    > div {
      min-width: 100%;
      max-width: 100%;
      box-shadow: none;
      border: null;
      font: 15px/20px 'Lato', 'Helvetica', 'Arial', sans-serif;
      background-color: #e6e6e6;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: solid 1px grey;
    }

    input {
      // width: 100%;
      max-width: 100%;
      outline: none;
      margin: 8px 0;
      font-size: '24 !important';
      height: 38px;
    }
  }

  [class$="--ResultsAttribution"] {
    display: none;
  }
}

.video-container div {
  width: 80%; /* Set the width of the iframe */
  max-width: 640px;
}

.video-container iframe {
  width: 100%; /* Set the width of the iframe */
}

.MuiInputBase-input {
  box-sizing: unset !important;
}